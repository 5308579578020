<template>
    <div class="detail">
        <div class="detail-name">{{ info.title }} <img src="../../public/images/new/closeicon.png" alt="" @click="closeModal" /></div>
        <div class="detail-desc" v-html="info.description"></div>
    </div>
</template>

<script>
export default {
    props: ['type'],
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        let categoryType='USER_AGREEMENT';
        if(this.type==2){
            categoryType='SALES_AGREEMENT'
        }else if(this.type==3){
            categoryType='LAND_AGREEMENT'
        }
        this.$post(this.URL.home.article, {
            categoryType: categoryType
        }).then(res => {
            if (res.code == 0) {
                this.info = res.data;
                document.title = this.name;
            } else {
                this.$message.error(res.message)
            }
        })
    },
    methods:{
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="less" scoped>
.detail {
    width: 480px;
    padding: 32px;

    .detail-name {
        position: relative;
        padding: 0 52px 20px 0;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        img{
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .detail-desc {
        max-height: 412px;
        overflow-y: scroll;
        font-size: 16px;
        color: #A8A8A8 !important;

        &::-webkit-scrollbar {
            width: 2px; //修改滚动条宽度
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #363636;
            border-radius: 8px;
        }
    }
}


@media (max-width:767px) {
    .detail {
        padding: 16px;
        width: calc(100vw - 8px);

        .detail-name {
            font-size: 16px;
        }

        .detail-desc {
            font-size: 14px;
        }
    }
}
</style>